<template>
  <div
    class="SSOPage"
    v-loading="loading"
    :element-loading-text="loadingText"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <el-result
      icon="error"
      v-if="result"
      title="登录失败"
      subTitle="请返回重新登陆"
    >
      <template slot="extra">
        <el-button size="medium" @click="hogback">返回</el-button>
      </template>
    </el-result>
  </div>
</template>

<script>
import { check, typeRange } from '@/utils/util'
import { lionLogin } from '@/api/sys'
import { setItem } from '@/utils/storage'
import { USERINFO } from '@/constant'
const { Message } = $PCommon

export default {
  name: 'SSOPage',
  data() {
    return {
      loading: false,
      loadingText: '登录中...',
      result: false
    }
  },
  mounted() {
    let query = this.$route.query
    if (
      query &&
      check(query) === typeRange.object &&
      Object.keys(query).length
    ) {
      let { lionId, email, fullName, agencyName, nonce, signature } = query
      this.loading = true
      const obj = { lionId, email, fullName, agencyName, nonce, signature }
      lionLogin(obj)
        .then((res) => {
          if (res && res.code === 200) {
            this.loading = false
            this.$store.commit('user/setToken', res.data.accessToken)
            this.$store.commit('user/setUserRole', res.data.userType)
            setItem('userName', res.data.username)
            if (res.data.menuList) {
              setItem(USERINFO, res.data.menuList)
              Message.success('登录成功')
              this.$router.push('/')
            } else {
              Message.warning('账号无权限!')
              this.$store.dispatch('user/logout')
            }
          } else {
            this.loading = false
            this.result = true
            // this.$store.dispatch('user/logout')
          }
        })
        .catch(() => {
          this.loading = false
          this.result = true
        })
    } else {
      this.loading = false
      this.result = true
    }
  },
  methods: {
    hogback() {
      this.$router.replace({
        path: '/login'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.SSOPage {
  width: 100%;
  height: 100%;
  ::v-deep.el-loading-spinner {
    .el-icon-loading {
      font-size: 30px;
    }
    .el-loading-text {
      font-size: 25px;
    }
  }
}
</style>
