import { render, staticRenderFns } from "./SSOPage.vue?vue&type=template&id=040f24d1&scoped=true"
import script from "./SSOPage.vue?vue&type=script&lang=js"
export * from "./SSOPage.vue?vue&type=script&lang=js"
import style0 from "./SSOPage.vue?vue&type=style&index=0&id=040f24d1&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "040f24d1",
  null
  
)

export default component.exports